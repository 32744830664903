.btn {
	font-family: 'Montserrat', sans-serif;
	font-weight: 600;
	padding: 10px 30px;
	text-transform: uppercase;
	display: inline-block;
	border-radius: 3px;
	transition: background .35s, color .35s;
	text-decoration: none!important;

	&:hover,
	&:focus {
		text-decoration: none!important;
	}

	&--white {
		background: #fff;
		color: $base!important;

		&:hover,
		&:active {
			background: $whitehover;
		}
	}

	&--green {
		background: $base;
		color: #fff!important;

		&:hover,
		&:active {
			background: $darkbase;
		}
	}

	&--green-border {
		border: 2px solid $base;
		color: $base!important;

		&:hover,
		&:active {
			background: $base;
			color: #fff!important;
		}
	}

	&-download-img {
		display: inline-block;
		position: relative;
		clear: both;

		&:before {
			content: '';
			background: rgba(255,255,255,0.5);
			position: absolute;
			left: 0;
			top: 0;
			right: 0;
			bottom: 0;
		}

		&:after {
			font-family: 'icomoon' !important;
			content: '\e907';
			position: absolute;
			left: 0;
			top: 0;
			right: 0;
			bottom: 0;
			margin: auto;
			width: 34px;
			height: 47px;
			font-size: 2em;
			color: $base;
		}

		&:before,
		&:after {
			display: none
		}

		&:hover,
		&:focus {
			&:before,
			&:after {
				display: block;
			}
		} 
	}
}