
@mixin mobile {
	@media (max-width: 767px) {
		@content;
	}
}

@mixin tablet {
	@media (min-width: 768px) and (max-width: 1023px) {
		@content;
	}
}

@mixin desktop {
	@media (min-width: 1024px) {
		@content;
	}
}

@mixin lgDesktop {
	@media (min-width: 1440px) {
		@content;
	}
}