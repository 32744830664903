//----------------------------------*\
// SCSS MIXINS
//----------------------------------*/

@import 'mixins/_position.scss';

//----------------------------------*\
// CONFIG MODULES
//----------------------------------*/

// Config modules
@import 'modules/_breakpoints.scss';
@import 'modules/_vars.scss';
@import 'modules/_clearfix.scss';
@import 'modules/_defaults.scss';
@import 'modules/_normalize.scss';
//----------------------------------*\
// PARTIALS
//----------------------------------*/

@import 'partials/_header.scss';
@import 'partials/_nav.scss';
@import 'partials/_sidebar.scss';
@import 'partials/_main.scss';
@import 'partials/_footer.scss';

//----------------------------------*\
// OBJECTS & MODULES
//----------------------------------*/
@import 'https://fonts.googleapis.com/css?family=Montserrat|Open+Sans';
@import 'modules/_buttons.scss';
@import 'modules/_inputs.scss';
@import 'modules/_lists.scss';
@import 'modules/_typography.scss';
@import 'modules/_misc.scss';
@import 'modules/_app.scss';

// Enhancement modules
@import 'modules/_print.scss';

//----------------------------------*\
// VENDOR
//----------------------------------*/

@import 'vendor/_external.scss';

.container {
	padding-left: 20px;
	padding-right: 20px;
	max-width: 1000px;
	margin-left: auto;
	margin-right: auto;

	@include lgDesktop {
		max-width: 1400px;
	}
}

.main {
	margin-top: 0;
	padding-bottom: 50px;
	color: #333;

	@include mobile {
		margin-top: 100px;
	}

	a {
		color: $base;
	}

	h2,
	h3 {
		margin-top: 30px;
		margin-bottom: 5px;

		+ p {
			margin-top: 5px;
		}
	}

	.tagline {
		font-size: 1.75em;
		color: #333;
		margin-bottom: 20px;
	}

	.section-title {
		@extend h4;
		font-size: 1.125em;
	}

	.container {
		max-width: 800px;

		@include lgDesktop {
			max-width: 900px;
		}
	}

	&.homepage {
		padding-bottom: 0;

		.container {
			max-width: 1000px;

			@include lgDesktop {
				max-width: 1400px;
			}
		}
	}
}

.banner {
	height: 350px;
	position: relative;

	@include mobile {
		height: 170px;
	}

	h1 {
		color: #fff;
		text-align: center;
		position: absolute;
		top: 40%;
		padding: 15px 25px;
		width: auto;
		left: 50%;
		margin: auto;
		z-index: 2;
		transform: translateX(-50%);

		@include mobile {
			top: 25%;
		}

		@include tablet {
			top: 55%;
		}

		&:before {
			content: '';
			background: rgba(0,0,0,0.5);
			// box-shadow: 0 0 30px rgba(0,0,0,0.8);
			// border-radius: 30px;
			display: block;
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			z-index: -1;
		}
	}

	.background {
		background-size: cover;
		background-position: center center;
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
	}
}

.breadcrumb {
	margin: 25px 0;

	a {
		text-decoration: none;

		&:hover,
		&:focus {
			text-decoration: none;
		}
	}

	&__item {
		font-size: 0.875em;

		&:after {
			content: '▸';
			display: inline-block;
			margin: 0 5px;
		}

		&:last-of-type {
			color: $base;

			&:after {
				display: none;
			}
		}
	}
}

//----------------------------------*\
// HOME
//----------------------------------*/

@include keyframe(bounce) {
    0% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(-10px);
    }

    100% {
        transform: translateY(0);
    }
}

.slide {
	min-height: 100vh;
	position: relative;
	background-size: cover;
	background-position: 25% center;

	@include desktop {
		&:first-of-type {
			min-height: calc(100vh - 110px);
		}
	}

	a {
		text-decoration: none;
		color: #fff;

		&:hover,
		&:focus {
			text-decoration: none;
		}
	}

	.js-scroll-next {
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		width: 64px;
		font-size: 3em;
		margin: auto;
		text-align: center;
		opacity: 0.5;
		@include animation(0, infinite, bounce);

		@include mobile {
			display: none;
		}

		&:hover {
			opacity: 1;
		}
	}

	&:first-of-type {
		background-image: url(../img/slide-who.jpg);
	}

	&:nth-of-type(2) {
		background-image: url(../img/slide-how.jpg);
	}

	&:nth-of-type(3) {
		background-image: url(../img/slide-cactus.jpg);
	}

	@include mobile {
		padding-top: 50px;
		padding-bottom: 70px;
		min-height: auto;
	}

	h2 {
		font-size: 2.25em;
		line-height: 1.2;
		margin: 0.83em 0;

		@include mobile {
			font-size: 1.75em;
		}

		&:after {
			content: '';
			height: 3px;
			width: 30px;
			background-color: #fff;
			display: block;
			margin-top: 20px;
		}
	}

	&:before {
		content: '';
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	&__content {
		width: 60%;
		max-width: 580px;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);

		@include mobile {
			width: 100%;
			max-width: 450px;
			position: relative;
			top: auto;
			transform: translateY(0);
		}

		.btn {
			margin-top: 15px;
		}
	}

	&--dark {
		color: #fff;
		background-color: #333;

		h2 {
			color: #fff;

			&:after {
				background-color: #fff;
			}
		}

		&:before {
			@include linear-gradient(to right, rgba(0,0,0,0.6) 0%, rgba(0,0,0,0.4) 80%, rgba(0,0,0,0) 100%);

			@include mobile {
				background: rgba(0,0,0,0.4);
			}
		}
	}

	&--light {
		h2 {
			color: #000;

			&:after {
				background-color: #444;
			}
		}

		&:before {
			@include linear-gradient(to right, rgba(255,255,255,0.6) 0%, rgba(255,255,255,0.4) 80%, rgba(255,255,255,0) 100%);

			@include mobile {
				background: rgba(255,255,255,0.4);
			}
		}
	}
}



//----------------------------------*\
// Mission
//----------------------------------*/

.graph-mission {
	position: relative;
	margin-top: 90px;
	margin-bottom: 30px;

	&:before {
		content: '';
		position: absolute;
		background: $light-grey;
		left: 20px;
		top: 20px;
		right: 20px;
		bottom: -20px;

		@include mobile {
			display: none;
		}
	}

	&:after {
		content: '';
		display: block;
		clear: both;
	}

	&__item {
		background: #fff;
		border: 3px solid $light-grey-border;
		width: 23%;
		margin-left: 2.66%;
		float: left;
		padding: 15px;
		position: relative;

		&:first-of-type {
			margin-left: 0;
		}

		@include mobile {
			width: 100%;
			margin-left: 0;
			margin-bottom: 75px;

			&:last-of-type {
				margin-bottom: 30px;
			}
		}
	}

	h3 {
		text-align: center;
		margin-top: 60px;
		margin-bottom: 10px;
	}

	img {
		position: absolute;
		left: 0;
		right: 0;
		width: 136px;
		top: -68px;
		margin: auto;
	}

	ul {
		padding-left: 15px;
		margin-top: 10px;
	}

	li {
		font-size: 0.875em;
	}
}



//----------------------------------*\
// Accordion
//----------------------------------*/

.accordion {
	&__title {
		border-bottom: 2px solid $grey;
		padding: 5px 0;
		position: relative;

		h2 {
			color: $grey;
			margin: 0;
			padding-right: 45px;
		}

		.is-active & {
			border-color: $base;

			h2 {
				color: $base;
			}

			.js-accordion-toggle {
				background-color: $base;

				span:first-of-type {
					transform: scaleY(1);
				}
			}
		}
	}

	.js-accordion-toggle {
		display: inline-block;
		width: 39px;
		height: 36px;
		padding: 3px;
		background-color: $grey;
		position: absolute;
		bottom: 0;
		right: 0;

		span {
			position: absolute;
			height: 3px;
			width: 15px;
			background: #fff;
			top: 17px;
			left: 12px;

			&:first-of-type {
				height: 16px;
				width: 3px;
				left: 18px;
				top: 10px;
				transform: scaleY(0);
				transition: transform .35s;
			}
		}
	}

	&__featured {
		width: 200px;
		float: right;
		margin-left: 20px;
		margin-top: 15px;

		img {
			display: block;
			max-width: 100%;
		}

		@include mobile {
			float: none;
			width: 100%;
			margin: 0;
		}
	}

	&__clients {

		h3 {
			font-size: 1.125em;
		}

		ul {
			padding-left: 0;
		}

		li {
			display: inline-block;
			margin-right: 20px;
			margin-bottom: 5px;

			&:last-child {
				margin-right: 0;
			}

			@include desktop {
				margin-right: 25px;
			}
		}

		img {
			display: block;

			@include mobile {
				height: 60px;
			}
		}
	}

	&__content {
		padding: 10px 0px;
		display: none;

		p {
			width: calc(100% - 250px);
			display: inline-block;

			@include mobile {
				width: 100%;
			}
		}

		em {
			font-size: 0.875em;
			line-height: 1.5;
			display: block;
			margin-top: 10px;
		}
	}
}



//----------------------------------*\
// Contact
//----------------------------------*/

.map {
	background: #eee;
	display: inline-block;
	width: 60%;
	vertical-align: top;

	@include mobile {
		width: 100%;
	}

	iframe {
		width: 100%;

		@include mobile {
			height: 300px;
		}
	}
}

.contact {
	width: 30%;
	margin-left: 9%;
	display: inline-block;
	vertical-align: top;

	@include mobile {
		width: 100%;
		margin-left: 0;
		padding-left: 30px;
		margin-top: 20px;
	}

	a,
	span {
		display: block;
	}

	&__item {
		margin-bottom: 20px;
		position: relative;

		.icon {
			position: absolute;
			left: -24px;
			display: inline-block;
			color: $grey;
			padding-top: 5px;
		}
	}
}