* {
	box-sizing: border-box;
	font-smoothing: antialiased;
	text-rendering: optimizeLegibility;
}
html {
	font-size: 62.5%;
}
body {
	font: 400 16px/1.6 'Open Sans', Helvetica, Arial;
	color: #444;
	transition: all .4s .3s ease-in;
}
body,html{
	min-height: 100vh;
}
.visuallyhidden {
	position: absolute; 
	overflow: hidden; 
	clip: rect(0 0 0 0); 
	height: 1px; width: 1px; 
	margin: -1px; padding: 0; border: 0; 
}

ul {
	padding-left: 20px;
}

a {
	color: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: 'Montserrat', sans-serif;
	font-weight: 400;
	line-height: 1.3;
}

h1 {
	font-size: 3em;
}

h2 {
	font-size: 1.5em;

	.main & {
		color: $base;
	}
}

h3 {
	font-size: 1.125em;
}

.image-left {
	float: left;
	margin-right: 15px;
	margin-bottom: 10px;
}

.image-right {
	float: right;
	margin-left: 15px;
	margin-bottom: 10px;
}