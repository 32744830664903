.header {
	font-family: 'Montserrat', sans-serif;
	background: #fff;
	padding: 5px 0;
	box-shadow: 0 3px 5px rgba(0,0,0,0.1);
	position: fixed;
	top: 0;
	z-index: 10;
	width: 100%;

	@include desktop {
		padding: 10px 0 0;
		height: 110px;
		position: relative;
	}

	h1 {
		margin: 0;
		line-height: 1;
	}

	a {
		text-decoration: none;

		&:hover,
		&:focus {
			text-decoration: none;
		}
	}

	.nav {
		display: none;

		@include desktop {
			display: inline-block;
			float: right;
			margin-top: 15px;
		}

		ul {
			list-style: none;
			padding: 0;
			margin: 0;
		}

		li {
			display: block;
			position: relative;
			border-top: 1px solid #ededed;

			ul {
				display: none;

				.is-active {
					> a {
						color: $base;
					}
				}

				a {
					padding: 15px 20px;
				}
			}

			&.is-active {
				> a {
					color: $base;
				}
			}

			@include desktop {
				border-top: 0;
				display: inline-block;
				margin-left: 20px;

				ul {
					position: absolute;
					background: $base;
					color: #fff;
					margin-left: -20px;
					width: 315px;

					li {
						display: block;
						margin: 0;

						&.is-active {
							> a {
								color: $whitehover;
							}
						}
					}

					a {
						border: 0;
						padding: 15px 20px;
						&:hover,
						&:focus,
						.is-active & {
							color: $whitehover;
						}
					}

				}

				&.is-active {
					> a {
						border-color: $base;
					}
				}

				&:hover,
				&:focus {

					> a {
						border-color: $base;
						color: $base;
					}
					ul {
						display: block;
					}
				}
			}
		}

		a {
			display: block;
			text-transform: uppercase;
			font-weight: 600;

			padding: 15px 0;

			.is-active & {
				color: $base;
			}

			&.parent {
				padding-right: 30px;
				@extend .icon-chevron-down;

				@include desktop {
					padding-right: 0;
				}

				&:before {
					font-family: 'icomoon' !important;
					display: inline-block;
					position: absolute;
					right: 10px;

					@include desktop {
						display: none;
					}
				}

				&.is-active {
					@extend .icon-chevron-up;
				}
			}

			@include desktop {
				border-bottom: 6px solid transparent;
				padding: 15px 0 5px;

				.is-active & {
					border-color: $base;
					color: $base;
				}
			}
		}
	}

	&:after {
		content: '';
		clear: both;
		display: block;
	}
}

.logo {
	display: inline-block;
	a {
		display: inline-block;
		max-width: 170px;

		@include mobile {
			max-width: 150px;
		}

		img {
			display: block;
			max-width: 100%;
		}
	}
}

.mobile-menu {
	position: absolute;
	width: 36px;
	height: 30px;
	top: 40px;
	right: 20px;

	@include tablet {
		top: 50px;
	}

	@include desktop {
		display: none;
	}

	span {
		display: block;
		position: absolute;
		height: 3px;
		width: 100%;
		background: $base;
		opacity: 1;
		left: 0;
		-webkit-transform: rotate(0deg);
		-moz-transform: rotate(0deg);
		-o-transform: rotate(0deg);
		transform: rotate(0deg);
		-webkit-transition: .25s ease-in-out;
		-moz-transition: .25s ease-in-out;
		-o-transition: .25s ease-in-out;
		transition: .25s ease-in-out;


		&:nth-child(1) {
			top: 0px;
		}

		&:nth-child(2),&:nth-child(3) {
			top: 10px;
		}

		&:nth-child(4) {
			top: 20px;
		}
	}

	&.is-active {
		span:nth-child(1) {
			top: 10px;
			width: 0%;
			left: 50%;
		}

		span:nth-child(2) {
			-webkit-transform: rotate(45deg);
			-moz-transform: rotate(45deg);
			-o-transform: rotate(45deg);
			transform: rotate(45deg);
		}

		span:nth-child(3) {
			-webkit-transform: rotate(-45deg);
			-moz-transform: rotate(-45deg);
			-o-transform: rotate(-45deg);
			transform: rotate(-45deg);
		}

		span:nth-child(4) {
			top: 10px;
			width: 0%;
			left: 50%;
		}	
	}
	
}
