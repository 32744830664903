.footer {
	text-align: center;
	background: url(../img/footer.jpg) no-repeat;
	background-size: cover;
	background-color: $base;
	color: #fff;

	a {
		display: inline-block;
		text-decoration: none;

		&:hover,
		&:focus {
			text-decoration: none;
		}
	}

	&__section {
		padding: 30px 0 50px;

		@include mobile {
			padding: 20px 0;
		}

		.btn {
			margin-top: 30px;
		}
	}

	&__link {
		margin-right: 20px;
		padding-right: 20px;
		border-right: 1px solid #fff;
		line-height: 1;

		&:last-child {
			margin-right: 0;
			border: 0;
			padding-right: 0;
		}
	}

	&__mini {
		padding: 10px 0;
		font-size: .9em;
		line-height: 1.2;
		background: rgba(0,0,0,0.3);

		a {
			text-decoration: underline;

			&:hover,
			&:focus {
				text-decoration: underline;
			}
		}

		p {
			margin: 0;
		}
	}
}
